.logout {
    cursor: pointer;
    font-size: 14px;
    color: blue;
    text-decoration: underline;
}

.adminTabs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px;
}

.adminTabs>div {
    flex: 1;
    padding: 10px;
    cursor: pointer;
    color: black;
    border: 1px solid #00897b;
    border-right: none;
}
.adminTabs>div:last-child {
    border-right: 1px solid #00897b;
}

.adminTabs>div:hover {
    background-color: #26a69a;
}

.adminTabs>div.active {
    background-color: white;
    color: white;
    background-color: #00897b;

}