.paymentDans {
    margin: 10px;
    border-collapse: collapse;
    border: 1px solid #bdbdbd;
    width: calc(100% - 20px);
}

.paymentDans tbody tr {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.paymentDans tbody tr td {
    border-bottom: 1px solid #bdbdbd;
    border-right: 1px solid #bdbdbd;
    padding: 10px 5px;
    flex: 1;
    word-wrap: break-word;
    overflow: hidden;
}


.paymentDans tbody tr td:nth-child(odd) {
    background-color: #eee;
}