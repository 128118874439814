.user {
    border: 1px solid #e0e0e0;
    margin: auto;
    display: flex;
    flex-direction: row;
}



.content {
    padding: 10px;
    width: 100%;
}

.formContent>div ul{
    background-color: #e0e0e0;
}
.formContent>div ul li{
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 10px;
    border-bottom: 1px solid white;
}

.formContent>div ul li:last-child{
    border-bottom: #e0e0e0;
}

/* resetPassword, forgotPassword уудын формыг хэлбэржүүлж байна */

.formContent {
    max-width: 400px;
    margin: auto;
}

.formContent>div {
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: flex-end;
}

.formContent>div>div {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.formContent>div>div:last-child {
    align-items: center;
}

.formContent>div>div>input:not([type='submit']) {
    height: 30px;
    padding-left: 10px;
}

.formContent>div>div>label {
    text-align: left;
}

.formContent>div>div input[type="submit"] {
    margin-top: 10px;
    width: 150px;
    padding: 13px;
    cursor: pointer;
    background-color: #455a64;
    border: none;
    color: white;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.formContent>div>div input[type="submit"]:hover {
    background-color: #435e6a;
}

.formContent>article {
    color: red;
}

@media only screen and (max-width: 600px) {

}