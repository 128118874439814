.qpayContain {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.qpayContain>img {
    width: 200px;
    height: 200px;
}

.qpayContain>span {
    font-size: 16px;
    width: 500;
    padding: 5px;
}

.qpayContain button {
    background-color: #ffc107;
    border: none;
    padding: 10px 35px;
    border-radius: 4px;
    box-shadow: 0 2px 3px 0 rgb(0 0 0 / 50%);
    cursor: pointer;
}

.qpayContain button:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.qpayContain .errorText {
    font-size: 12px;
    color: red;
}

.qpayContain>div {
    display: none;
}

@media only screen and (max-width: 600px) {
    .qpayContain {
        padding: 0;
    }

    .qpayContain>img {
        width: 150px;
        height: 150px;
    }

    .qpayContain>div {
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
        align-items: flex-start;
        width: 100%;
    }

    .qpayContain>div>span {
        font-size: 14px;
        padding-top: 20px;
        padding-left: 20px;
    }

    .qpayContain>div>a {
        display: flex;
        flex-direction: column;
        margin: 20px;
        align-items: center;
    }

    .qpayContain>div>a img {
        width: 60px;
    }

    .qpayContain>div>a:link {
        font-size: 12px;
        text-decoration: none;
        color: black;
    }
}