/* login and signup ийн Form-уудыг хэлбэржүүлж байна */
.editForm {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 300px;
    margin: auto;
    padding: 10px;
}

.editForm>div {
    text-align: left;
    margin: 7px;
}

.editForm>div:last-child {
    text-align: center;
}

.editForm>div>input {
    display: block;
    width: 100%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 30px;
}

.editForm>div>button {
    padding: 15px 30px;
    border: none;
    background-color: #b0bec5;
}

.editForm>div>button:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.desc {
    padding: 10px;
    background: forestgreen;
    color: white;
    border-radius: 4px;
}