.tabContain {
    border: 1px solid #bdbdbd;
    max-width: 500px;
    margin: 20px auto;
}

.tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.tabs div {
    background-color: #bdbdbd;
    width: 100%;
    padding: 10px;
    cursor: pointer;
}

.tabs div.active {
    background-color: white;
}



/* login and signup ийн Form-уудыг хэлбэржүүлж байна */
.formContent {
    max-width: 400px;
    margin: auto;
}

.formContent>div {
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: flex-end;
}

.formContent>div>span {
    text-align: left;
    text-decoration: underline;
    color: rgb(0, 116, 188);
    cursor: pointer;
    margin-top: 5px;
    font-size: 12px;
}

.formContent>div>div {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.formContent>div>div:last-child {
    align-items: center;
}

.formContent>div>div>input:not([type='submit']) {
    height: 30px;
    padding-left: 10px;
}

.formContent>div>div>label {
    text-align: left;
}

.formContent>div>div input[type="submit"] {
    margin-top: 10px;
    width: 150px;
    padding: 13px;
    cursor: pointer;
    background-color: #455a64;
    border: none;
    color: white;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.formContent>div>div input[type="submit"]:hover {
    background-color: #435e6a;
}

.formContent>article {
    color: red;
}


@media only screen and (max-width: 600px) {
    .formContent>div>div>label {
       display: none;
    }
}