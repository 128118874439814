.auctions {
    display: flex;
    flex-direction: column;
}

.auctions span.text {
    text-align: center;
    border: 1px solid #ffb300;
    padding: 10px;
    background: #ffc107;
}

.auctions .auction {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.auctions .auction h2{
    padding-right: 10px;
}

.auctions .auction img {
    border: 1px solid black;
}

.auctions .auction img:hover {
    transform: scale(1.1)
}

.auctions button.more {
    padding: 10px 20px;
    border: none;
    margin: 10px 0px;
    cursor: pointer;
    border-radius: 16px;
    background: #465a64;
    color: white;
    align-self: center;
}

.auctions button.more:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: #344249;
}

.carDetails {
    padding: 10px;
    box-shadow: #eee;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
}

.carDetails>div {
    border: 1px solid #bdbdbd;
    margin-top: 10px;
    border-radius: 4px;
}

.carDetails .errorText {
    background-color: #ffc107;
    padding: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: justify;
}

.carDetails .carInfoTitle {
    background-color: #b0bec5;
    padding: 10px;
    font-weight: 500;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.imgContain {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    background-color: white;
}

.imgContain div.img {
    height: 96px;
    width: 96px;
    display: inline-flex;
    margin: 5px;
}

.carDetails .img img {
    width: inherit;
    cursor: pointer;
}

.table {
    margin: 10px;
    border-collapse: collapse;
    border: 1px solid #bdbdbd;
    width: calc(100% - 20px);
}

.table tbody tr {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.table tbody tr td {
    border-bottom: 1px solid #bdbdbd;
    border-right: 1px solid #bdbdbd;
    padding: 10px 5px;
    flex: 1;
    word-wrap: break-word;
    overflow: hidden;
}


.table tbody tr td:nth-child(odd) {
    background-color: #eee;
}

.price {
    display: flex;
    flex-direction: column;
}

.price>div {
    margin-bottom: 10px;
}

.packageContain {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.packageContain .package {
    background-color: #FBF5B1;
    padding: 5px 30px;
    border-radius: 4px;
    margin: 5px;
    cursor: pointer;
    box-shadow: 0px 2px 3px 0px rgb(0 0 0 / 50%);
    font-size: 12px;

}

.packageContain .package.selected {
    background-color: #87680a;
}

/* Төлбөр төлөх гэдэг товчлуур */
.price button.payment {
    background-color: #ffc107;
    border: none;
    padding: 10px 35px;
    border-radius: 4px;
    box-shadow: 0 2px 3px 0 rgb(0 0 0 / 50%);
    cursor: pointer;
    margin: 5px;
}

/* Эрхээ ашиглан харах, купон кодоор харах */
.lotButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.lotButtons>div {
    box-sizing: border-box;
}

.lotButtons>div>input {
    margin: 10px;
    margin-right: 0px;
    padding: 10px 20px;
}

.lotButtons>div>button {
    margin: 10px;
    cursor: pointer;
    color: white;
    padding: 10px 35px;
    background-color: #465a64;
    border-radius: 4px;
    border: 1px solid #465a64;
    box-shadow: 0 2px 3px 0 rgb(0 0 0 / 50%);
}

button.lot {
    background-color: #465a64;
    float: right;
    color: white;
    margin: 10px;
    border: none;
    padding: 10px 35px;
    border-radius: 4px;
    box-shadow: 0 2px 3px 0 rgb(0 0 0 / 50%);
    cursor: pointer;
}

button.downloadPDF {
    cursor: pointer;
}

.loginReq {
    padding: 10px;
}

.loginReq mark {
    cursor: pointer;
}

/* Үнэлгээнүүдийг тайлбарыг харуулж байгаа table үүдийг хэлбэржүүлж байна */

.tables {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.tables .tablesCol {
    flex: 1;
    padding: 10px;
}

table.auction {
    width: 100%;
    border-collapse: collapse;

}

table.auction thead th,
table.auction tbody td {
    padding: 5px;
    text-align: left;
    border: 1px solid black;
}

table.auction thead tr:first-child th {
    background-color: #d9ead3;
}

table.auction thead th {
    text-align: center;
    background-color: #a4c2f4;
}

@media only screen and (max-width: 600px) {
    .table tbody tr td {
        min-width: 40%;
        flex: 1;
        word-break: break-all
    }
}