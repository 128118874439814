.blogTitle {
    padding: 20px 0px;
    background-color: rgb(240, 240, 240);
}

.blogContent {
    padding: 10px;
    max-width: 760px;
    margin: auto;
}
.blogContent h3{
    margin-top: 50px;
}

.blogContent p,
.blogContent ul li {
    text-align: justify;
    line-height: 20px;
}

.blogContent p div.imgs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.blogContent div.imgs img {
    max-width: 100%;
    margin: 5px;
    flex: 1;
}


table.auction {
    width: 100%;
    border-collapse: collapse;

}

table.auction thead th,
table.auction tbody td {
    padding: 10px;
    text-align: left;
    border: 1px solid black;
}

table.auction thead tr:first-child th {
    background-color: #d9ead3;
}

table.auction thead th {
    text-align: center;
    background-color: #a4c2f4;
}

.blogContent .twoCol {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.blogContent .twoCol>div,
.blogContent .twoCol>p {
    flex: 1;
    width: 50%;
}