html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
  font-size: 14px;
  width: 100%;
  background-color: #eee;
  min-height: 100vh;
}

.container {
  padding: 10px;
  max-width: 1100px;
  margin: auto;
  border: 1px solid #bdbdbd;
  background-color: white;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.css-1xdhyk6 {
  padding: 20px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* warningText: notification-ийг хэлбэржүүлж байна */
article.is-warning {
  border: 1px solid #ffc0c0;
  padding: 16px;
  background-color: #ffebe9
}

article.is-warning>div {
  font-size: 14px;
}

.toolbarClassName {
  border: none;
  border-bottom: 1px solid #f1f1f1;
  background-color: #f1f1f1 !important;
}

.editorClassName {
  min-height: 300px;
  padding: 10px;
  border: 1px solid #f1f1f1;
}

.text-right {
  text-align: right;
  padding: 10px;
}

button.btn__theme {
  padding: 10px 40px;
  background-color: lightskyblue;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}