.home ul li {
    text-align: left;
}

.home {
    display: flex;
    flex-direction: column;
}

.home>div {
    flex: 1;
}

.home div.iconList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    text-align: left;
    padding: 20px;
}

.home div.iconList span {
    flex: 1 0 40%;
    text-align: left;
    margin: 10px;
    border-radius: 4px;
}

.home div.iconList span>svg {
    margin-right: 5px;
    vertical-align: middle;
}

.desc {
    border-radius: 4px;
    margin-top: 10px;
    background-color: #eeeeee;
}

.desc div.twoCol {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.desc div.twoCol>div {
    flex: 1;
}

.searchContain {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #bdbdbd;
}

.searchContain span.example {
    cursor: pointer;
    color: blue;
    margin-bottom: 10px;
    text-decoration: underline;
}

.textField,
.searchField {
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.searchField>div {
    display: flex;
    flex-direction: column;
    padding: 5px 0px;
}

.searchField>div>input {
    padding: 10px;
    width: 250px;
    font-size: 16px;
    border: none;
}

.searchField>label {
    font-weight: 500;
    padding: 5px 0px;
    padding-right: 10px;

}

.searchField>button {
    padding: 10px 40px;
    border: none;
    margin-left: 20px;
    background-color: #455a64;
    cursor: pointer;
    color: white;
}

.searchField>button:hover {
    background-color: #435e6a;
}

.errorText {
    background-color: #ffc107;
    padding: 15px;
    text-align: justify;
}

@media only screen and (max-width: 600px) {

    .searchField>input,
    .searchField>label,
    .searchField>button {
        margin-top: 5px;
    }

    .searchField>button {
        width: 270px;
        margin: 10px 0px;
        border-radius: 4px;
    }

    .searchField {
        flex-direction: column;
    }
}