.header {
    /* display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap; */
    background-color: #455a64;
    /* height: 50px; */
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    /* align-items: center; */
    z-index: 1999999;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px;
}

.header .headerContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1120px;
    margin-right: auto;
    margin-left: auto;
    align-items: center;
    height: 50px;
}

.header .title {
    color: white;
    font-weight: 500;
    text-decoration: none;
    padding-left: 20px;
}

.menus {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}



.menus .menuItem {
    padding: 0px 20px;
    color: white;
    align-self: center;
    text-decoration: none;
}

.menus .menuItem svg {
    vertical-align: middle;
}

@media only screen and (max-width: 600px) {
    .menus {}
}