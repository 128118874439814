
/* login and signup ийн Form-уудыг хэлбэржүүлж байна */
.formContent {
    max-width: 400px;
    margin: 50px auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.formContent>form {
    display: flex;
    flex-direction: column;
    padding: 40px;
    justify-content: flex-end;
}

.formContent>form>span {
    text-align: left;
    text-decoration: underline;
    color: rgb(0, 116, 188);
    cursor: pointer;
    margin-top: 5px;
    font-size: 12px;
}

.formContent>form>div {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.formContent>form>div:last-child {
    align-items: center;
}

.formContent>form>div>input:not([type='submit']) {
    height: 30px;
    padding-left: 10px;
}

.formContent>form>div input[type="submit"] {
    margin-top: 10px;
    width: 150px;
    padding: 13px;
    cursor: pointer;
    background-color: #455a64;
    border: none;
    color: white;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.formContent>form>div input[type="submit"]:hover {
    background-color: #435e6a;
}

.formContent>article {
    color: red;
}

@media only screen and (max-width: 600px) {
    .formContent>form>div>label {
       display: none;
    }
}