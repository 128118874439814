.footer {
    height: 60px;
    width: 100%;
}

.footer .footerContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1120px;
    margin-right: auto;
    margin-left: auto;
    align-items: center;
}

.menus {
    padding: 20px;
}

.menus a {
    text-decoration: none;
}