.Popup {
    position: fixed;
    z-index: 500;
    background-color: white;
    max-width: 500px;
    border: 1px solid gray;
    box-shadow: 1px 1px 1px black;
    border-radius: 4px;
    padding: 20px;
    padding-top: 0;
    top: 15%;
    transition: all 0.6s ease-out;
    display: flex;
    flex-direction: column;
    font-family: auto;
    font-size: 18px;
    left: 0;
    right: 0;
    margin: auto;
}

.button {
    margin-left: auto;
    border: none;
    font-size: 30px;
    cursor: pointer;
    padding: 10px;
    background-color: inherit;
}

.button:hover {
    opacity: .9;
}

.Popup .text>img {
    margin-top: 10px;

}