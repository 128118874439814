.users {
    margin: 10px;
    padding: 20px;
    width: calc(100% - 60px);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    overflow-x: scroll;
}

.users table {
    width: inherit;
    border-collapse: collapse;
}

.users table thead tr th {
    background-color: lightseagreen;
    border: 1px solid lightseagreen;
    padding: 10px;
}

.users table tbody tr td {
    padding: 10px;
    flex: 1;
    word-wrap: break-word;
    border: 1px solid lightseagreen;
}

.users table tbody tr td input {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 30px;
}

.users table tbody tr:nth-child(odd) td {
    background-color: #cfd8dc;
}

.users table td button.edit {
    cursor: pointer;
    padding: 5px 20px;
    background-color: #40c4ff;
    border: none;
}

.users table td button.edit:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}